import React from "react";
import "./Navigation.css";
import icons from "../icons.svg";
import { Link } from "react-router-dom";

function Navigation(props: { visible: boolean }) {
  return (
    <div id="head-nav" className={props.visible ? "nav-visible" : "nav-hidden"}>
      <div id="inner-nav" className="container">
        <Link to="/">
          <svg id="nav-logo" viewBox="0 0 60 60">
            <use xlinkHref={`${icons}#logo`} />
          </svg>
        </Link>
        {/* <ul id="nav" className="fadeIn">
          <li>
            <Link to="/">about</Link>
          </li>
          <li>
            <Link to="/projects">projects</Link>
          </li>
          <li>
            <Link to="/contact">contact</Link>
          </li>
        </ul> */}
      </div>
    </div>
  );
}

export default Navigation;
