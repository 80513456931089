import React, { useState, useEffect, lazy, Suspense } from "react";
import "./App.css";

import Impressum from "./pages/Impressum";
import Datenschutz from "./pages/Datenschutz";
import Navigation from "./components/Navigation";
import ScrollToTop from "./components/ScrollToTop";
import { Route, Switch, useLocation } from "react-router-dom";
import { AnimatePresence, Variants, motion } from "framer-motion";

const Home = lazy(() => import("./pages/Home"));
const Contact = lazy(() => import("./pages/Contact"));
const Footer = lazy(() => import("./components/Footer"));
const Projects = lazy(() => import("./pages/Projects"));

function App() {
  const [headerVisible, setHeaderVisible] = useState(true);
  const location = useLocation();
  const animationVariants: Variants = {
    in: {
      opacity: 0,
    },
    present: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    },
  };

  useEffect(() => {
    let prevScrollpos = window.pageYOffset;
    const scrolled = () => {
      var currentScrollPos = window.pageYOffset;
      if (currentScrollPos <= 0) {
        currentScrollPos = 0;
      }
      setHeaderVisible(prevScrollpos >= currentScrollPos);
      prevScrollpos = currentScrollPos;
    };
    document.addEventListener("scroll", scrolled, { passive: true });
    return () => {
      document.removeEventListener("scroll", scrolled);
    };
  }, []);
  return (
    <React.Fragment>
      <ScrollToTop />
      <Navigation visible={headerVisible} />
      <Suspense
        fallback={
          <AnimatePresence exitBeforeEnter>
            <motion.div
              exit="out"
              initial="in"
              animate="present"
              variants={animationVariants}
              style={{
                height: "100vh",
                width: "100vw",
                zIndex: 500,
                backgroundColor: "#272725",
              }}
            ></motion.div>
          </AnimatePresence>
        }
      >
        <AnimatePresence exitBeforeEnter>
          <Switch location={location} key={location.pathname}>
            <Route
              path="/"
              exact
              render={(props) => <Home variants={animationVariants} />}
            />
            <Route
              path="/projects"
              exact
              render={(props) => (
                <Projects variants={animationVariants} project={null} />
              )}
            />
            <Route
              path="/projects/:id"
              render={(props) => (
                <Projects
                  variants={animationVariants}
                  project={props.match.params.id}
                />
              )}
            />
            <Route
              path="/contact"
              render={(props) => <Contact variants={animationVariants} />}
            />
            <Route
              path="/impressum"
              render={(props) => <Impressum variants={animationVariants} />}
            />
            <Route
              path="/Datenschutz"
              render={(props) => <Datenschutz variants={animationVariants} />}
            />
          </Switch>
        </AnimatePresence>
        <Footer />
      </Suspense>
    </React.Fragment>
  );
}

export default App;
